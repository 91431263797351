import { Box, Button, Divider, Flex, Grid, Image, Input, LoadingOverlay } from "@mantine/core";
import { IconPhoto } from "@tabler/icons-react";
import React from "react";
import * as yup from "yup";
import { BusinessApi } from "../../apis";
import { message } from "../../utils/message";
import { Form } from "../form";
import { ImageField } from "../form/image-field";
import { RadioGroupField } from "../form/radiogroup-field";
import { TextField } from "../form/text-field";
import { TextareaField } from "../form/textarea-field";
import { MultiImageUpload } from "../upload/multi-image-upload";

type IFormData = {
  profileName: string | undefined;
  profileNameEng: string | undefined;
  profileBanners: any | undefined;
  logo: string | undefined;
  profileInfo: string | undefined;
  isConfirmed: boolean | string | undefined;
};

const schema = yup.object({
  profileName: yup
    .string()
    .required("Заавал бөглөнө!")
    .matches(/^[А-Яа-я0-9|Үү|Өө|Ёё\s-.]{2,256}$/, "Зөвхөн кирил үсэг авна"),
  profileNameEng: yup
    .string()
    .matches(/^[A-Za-z\s-.]{2,256}$/, "Зөвхөн латин үсэг авна")
    .required("Заавал бөглөнө!"),
  profileBanners: yup.array().max(5, "Ихдээ 5 зураг оруулна уу").nullable(),
  logo: yup.string().required("Заавал бөглөнө!"),
  profileInfo: yup.string().max(500, "Ихдээ 500 тэмдэгт авна!").trim().required("Заавал бөглөнө!"),
  isConfirmed: yup.boolean().nullable(),
});

export function MarketingForm({ onClose, action, reload, id }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);

  const [data, setFormData] = React.useState<IFormData>({
    profileName: undefined,
    profileNameEng: undefined,
    profileBanners: [],
    logo: undefined,
    profileInfo: undefined,
    isConfirmed: false,
    ...(action && action[1]
      ? {
          profileName: action[1].profileName,
          profileNameEng: action[1].profileNameEng,
          profileBanners: action[1].profileBanners?.map((c: any) => ({ url: c.url, isMain: true })),
          profileInfo: action[1].profileInfo,
          logo: action[1].logo,
          isConfirmed: action[1]?.isConfirmed?.toString(),
        }
      : null),
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);
    try {
      await BusinessApi.profile(id, {
        ...values,
        isConfirmed: JSON.parse(values?.isConfirmed as string),
        profileBanners: values?.profileBanners?.length > 0 ? values?.profileBanners : [],
      });
      message.success("Таны хүсэлт амжилттай!");
      setLoading(false);
      onClose();
      reload();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message!);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <LoadingOverlay visible={loading} />
              <Grid>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="NETWORK-Д ИЛ ХАРАГДАХ МЭДЭЭЛЭЛ" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <TextField name="profileName" label="Профайлын нэр" placeholder="Профайлын нэр оруулна уу" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <TextField name="profileNameEng" label="Профайлын нэр /Latin, English/" placeholder="Профайлын нэр /Latin, English/" />
                </Grid.Col>
                <Grid.Col span={12}>
                  <Input.Wrapper label=" Профайлд гарах баннер зураг">
                    {(() => {
                      const image = data?.profileBanners?.find((i: any) => i.selected || i.isMain);
                      return image ? (
                        <Box
                          w={280}
                          my={10}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #e3e3e3",
                            borderRadius: 5,
                            overflow: "hidden",
                          }}>
                          <Image src={image?.url} height={160} fit="cover" />
                        </Box>
                      ) : (
                        <Box
                          w={280}
                          h={160}
                          my={10}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #e3e3e3",
                            borderRadius: 5,
                          }}>
                          <IconPhoto size={46} color="gray" />
                        </Box>
                      );
                    })()}
                    <MultiImageUpload
                      initialData={values.profileBanners}
                      max={5}
                      onChange={(images) => {
                        setFormData((state) => ({ ...state, profileBanners: images }));
                        setFieldValue(
                          "profileBanners",
                          images.map((c: any) => ({ url: c.url, isMain: c?.selected })),
                        );
                      }}
                    />
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Input.Wrapper label="Лого зураг" required>
                    <Box sx={{ position: "relative" }}>
                      {values.logo ? (
                        <Box
                          w={200}
                          my={10}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #e3e3e3",
                            borderRadius: 5,
                            overflow: "hidden",
                          }}>
                          <Image height={150} alt="" src={values.logo} fit="cover" />
                        </Box>
                      ) : (
                        <Box
                          my={10}
                          w={200}
                          h={150}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid #e3e3e3",
                            borderRadius: 5,
                            overflow: "hidden",
                          }}>
                          <IconPhoto size={32} color={"gray"} />
                        </Box>
                      )}
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={4}>
                  <ImageField name="logo" label="" />
                </Grid.Col>

                <Grid.Col span={12}>
                  <TextareaField max={500} placeholder="DeHUB-д гарах товч танилцуулга..." name="profileInfo" label="Профайлд гарах товч танилцуулга" />
                </Grid.Col>

                <Grid.Col span={12}>
                  <RadioGroupField
                    label="Идэвхжүүлэх эсэх"
                    name="isConfirmed"
                    options={[
                      { label: "Үгүй", value: "false" },
                      { label: "Тийм", value: "true" },
                    ]}
                  />
                </Grid.Col>
              </Grid>

              <Divider />

              <Flex direction="row" justify="space-between">
                <Button type="submit">Хадгалах</Button>
              </Flex>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}

import { Box, Button, Divider, Flex, Grid, Input, LoadingOverlay, Text } from "@mantine/core";
import React from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { WarehouseApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/message";
import { Form } from "../form";
import { MultiSelectField } from "../form/multi-select-field";
import { RadioGroupField } from "../form/radiogroup-field";
import { SelectField } from "../form/select-field";
import { SwitchField } from "../form/switch-field";
import { TextField } from "../form/text-field";

type IFormData = {
  partnerRef: undefined;
  regUserName: string | undefined;
  name: string | undefined;
  district: string | undefined;
  warehouseUserId: string | undefined;
  isDefault: boolean | string | undefined;
  isBuyer: boolean | string | undefined;
  isSupplier: boolean | string | undefined;
  buyers: any;
  suppliers: any;
  phone: any;
  refCode: string | undefined;
  warehouseStatus: string | undefined;
};

const schema = yup.object({
  partnerRef: yup.string().trim().nullable(),
  regUserName: yup.string().trim().nullable(),
  name: yup.string().required("Заавал бөглөнө!"),
  profileBanners: yup.array().required("Заавал бөглөнө!"),
  district: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  phone: yup
    .string()
    .matches(/^[9|8|6]{1}[0-9]{7}$/, "Дугаарын формат буруу байна")
    .required("Заавал бөглөнө!"),
  warehouseUserId: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  refCode: yup.string().nullable(),
  isDefault: yup.boolean().nullable(),
  isBuyer: yup.boolean().nullable(),
  isSupplier: yup.boolean().nullable(),
  warehouseStatus: yup.string().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
  buyers: yup.array().when("isBuyer", {
    is: true,
    then: (field) => field.required("Заавал бөглөнө!"),
    otherwise: (field) => field.notRequired(),
  }),
  suppliers: yup.array().when("isSupplier", {
    is: true,
    then: (field) => field.required("Заавал бөглөнө!"),
    otherwise: (field) => field.notRequired(),
  }),
});

export function WarehouseForm({ onClose, action, reload }: any) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const { partnerStaffs, warehouseStatus, businesses, zipCodes } = useSelector((state: { general: IGeneral }) => state.general);

  const [data] = React.useState<IFormData>({
    partnerRef: undefined,
    regUserName: undefined,
    name: undefined,
    district: undefined,
    warehouseUserId: undefined,
    isDefault: false,
    isBuyer: false.toString(),
    isSupplier: false.toString(),
    buyers: [],
    suppliers: [],
    phone: undefined,
    warehouseStatus: undefined,
    refCode: undefined,
    ...(action && action[1]
      ? {
          partnerRef: action[1]?.partnerRefCode,
          regUserName: `${action[1]?.regUser.lastName ? action[1]?.regUser.lastName.trim()[0] + ". " : ""} ${action[1]?.regUser?.firstName || "-"}`,
          name: action[1]?.name,
          district: action[1]?.district,
          warehouseUserId: action[1]?.warehouseUserId,
          phone: action[1]?.phone,
          isDefault: action[1]?.isDefault,
          isBuyer: action[1]?.isBuyer?.toString(),
          isSupplier: action[1]?.isSupplier?.toString(),
          buyers: action[1]?.buyers?.map((c: any) => c.id),
          suppliers: action[1]?.suppliers?.map((c: any) => c.id),
          refCode: action[1]?.refCode,
          warehouseStatus: action[1]?.warehouseStatus,
        }
      : null),
  });

  const onSubmit = async (values: IFormData) => {
    setLoading(true);

    try {
      if (action && action[1]) {
        await WarehouseApi.update(action[1]?.id, {
          name: values.name,
          district: values.district,
          warehouseUserId: values.warehouseUserId,
          phone: values.phone,
          buyers: values?.buyers,
          suppliers: values?.suppliers,
          warehouseStatus: values.warehouseStatus,
          isDefault: JSON.parse(values?.isDefault as string),
          isBuyer: JSON.parse(values?.isBuyer as string),
          isSupplier: JSON.parse(values?.isSupplier as string),
        });
      } else {
        await WarehouseApi.create({
          name: values.name,
          partnerRef: values.partnerRef,
          regUserName: values.regUserName,
          district: values.district,
          warehouseUserId: values.warehouseUserId,
          buyers: values.buyers,
          suppliers: values.suppliers,
          phone: values.phone,
          warehouseStatus: values.warehouseStatus,
          refCode: values.refCode,
          isDefault: JSON.parse(values?.isDefault as string),
          isBuyer: JSON.parse(values?.isBuyer as string),
          isSupplier: JSON.parse(values?.isSupplier as string),
        });
      }
      message.success("Таны хүсэлт амжилттай!");
      setLoading(false);
      onClose();
      reload();
    } catch (error: any) {
      setLoading(false);
      message.error(error?.message!);
    }
  };

  return (
    <Form initialValues={data} validationSchema={schema} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <>
            <Flex sx={{ position: "relative" }} direction="column" gap="md">
              <LoadingOverlay visible={loading} />
              <Grid>
                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="ҮНДСЭН МЭДЭЭЛЭЛ" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <TextField disabled name="partnerRef" label="Партнерын Ref#" placeholder="Автоматаар үүснэ" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <TextField disabled name="regUserName" label="Бүртгэсэн ажилтан" placeholder="Бүртгэсэн ажилтан" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <TextField disabled placeholder="Агуулахын код" name="refCode" label="Агуулахын код" />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <TextField name="name" label="Агуулахын нэр" placeholder="Агуулахын нэр" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <SelectField
                    searchable
                    required
                    onChange={() => {
                      setFieldValue("khoroo", undefined);
                    }}
                    options={zipCodes.filter((item: any) => item.parent === "11000")?.map((c: any, index: number) => ({ label: c.name, value: c.code }))}
                    label="Сум, дүүрэг"
                    name="district"
                    placeholder="Сум, дүүрэг"
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <SelectField
                    searchable
                    options={partnerStaffs?.map((c: any) => ({ label: `${c?.lastName ? c?.lastName[0] + ". " : ""} ${c?.firstName}`, value: c?.id })) || []}
                    name="warehouseUserId"
                    label="Хариуцсан ажилтан"
                    placeholder="Хариуцсан ажилтан"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <TextField name="phone" label="Утасны дугаар" placeholder="Утасны дугаар" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <SelectField
                    options={warehouseStatus?.map((c: any) => ({ label: c?.name, value: c?.code })) ?? []}
                    name="warehouseStatus"
                    label="Төлөв"
                    placeholder="Төлөв"
                    required
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                  <Input.Wrapper label="Үндсэн эсэх">
                    <SwitchField name="isDefault" />
                  </Input.Wrapper>
                </Grid.Col>

                <Grid.Col span={12}>
                  <Divider color="gray" styles={{ label: { fontSize: 14, fontWeight: 500 } }} my="xs" label="АГУУЛАХЫН ТОХИРГОО" />
                </Grid.Col>

                <Grid.Col span={6}>
                  <Flex direction="column" gap="md">
                    <Text fw={500}>1. DeHUB Network-р Buyer тохиргоо</Text>
                    <RadioGroupField
                      onChange={() => {
                        setFieldValue("buyers", []);
                      }}
                      label="Салбарт худалдан авалт хийх эсэх"
                      name="isBuyer"
                      options={[
                        { label: "Үгүй", value: "false" },
                        { label: "Тийм", value: "true" },
                      ]}
                    />
                  </Flex>
                </Grid.Col>

                <Grid.Col span={6}>
                  <Flex direction="column" gap="md">
                    <Text fw={500}>1. DeHUB Network-р Supplier тохиргоо</Text>
                    <RadioGroupField
                      onChange={() => {
                        setFieldValue("suppliers", []);
                      }}
                      label="Салбараас нийлүүлэлт хийх эсэх"
                      name="isSupplier"
                      options={[
                        { label: "Үгүй", value: "false" },
                        { label: "Тийм", value: "true" },
                      ]}
                    />
                  </Flex>
                </Grid.Col>
                <Grid.Col span={6}>
                  {values.isBuyer === "true" ? (
                    <Box w="60%">
                      <MultiSelectField
                        options={businesses
                          ?.filter((d: any) => d?.type === "BUYER" && d?.businessStatus === "ACTIVE")
                          ?.map((c: any) => ({ label: `${c?.refCode} / ${c?.profileName}`, value: c?.id }))}
                        name="buyers"
                        label="Buyer бизнес Ref#"
                        placeholder="Buyer бизнес Ref#"
                      />
                    </Box>
                  ) : null}
                </Grid.Col>
                <Grid.Col span={6}>
                  {values.isSupplier === "true" ? (
                    <Box w="60%">
                      <MultiSelectField
                        options={businesses
                          ?.filter((d: any) => d?.type === "SUPPLIER" && d?.businessStatus === "ACTIVE")
                          ?.map((c: any) => ({ label: `${c?.refCode} / ${c?.profileName}`, value: c?.id }))}
                        name="suppliers"
                        label="Supplier бизнес Ref#"
                        placeholder="Supplier бизнес Ref#"
                      />
                    </Box>
                  ) : null}
                </Grid.Col>
              </Grid>
              <Divider />
              <Flex direction="row" justify="start">
                <Button color="teal" type="submit">
                  Хадгалах
                </Button>
              </Flex>
            </Flex>
          </>
        );
      }}
    </Form>
  );
}

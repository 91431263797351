import { ActionIcon, Alert, Avatar, Badge, Box, Button, CloseButton, Collapse, Divider, Drawer, Flex, Image, Modal, Tabs, Text, Tooltip } from "@mantine/core";
import { IconBuilding, IconEdit, IconPhoto, IconSpeakerphone } from "@tabler/icons-react";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BranchApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { SectorAddressForm } from "../../components/sector/sector-address-form";
import { SectorForm } from "../../components/sector/sector-form";
import { ColumnType, Table } from "../../components/table";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { message } from "../../utils/message";

export function Sector() {
  const breadcrumbs = useBreadcrumb();
  const [preAlert, setPreAlert] = React.useState<boolean>(true);
  const [id, setId] = useState<string | null>(null);
  const [action, setAction] = React.useState<any>([]);
  const tableRef = React.useRef<any>(null);
  const [imageOpen, setImageOpen] = useState<any[]>([]);
  const [activeTab, setActiveTab] = React.useState<string>("default");
  const { branchTypes, branchStatus } = useSelector((state: { general: IGeneral }) => state.general);

  const reloadTable = async () => {
    await tableRef.current.reload();
  };

  const isDefault = (value: boolean) => {
    switch (value) {
      case true: {
        return (
          <Badge variant="filled" color="green" size="md">
            Тийм
          </Badge>
        );
      }
      case false: {
        return (
          <Badge variant="filled" color="gray" size="md">
            Үгүй
          </Badge>
        );
      }
      default: {
        return (
          <Badge variant="filled" color="blue" size="md">
            {"N/A"}
          </Badge>
        );
      }
    }
  };

  const columns = useHeader({
    isDefault,
    branchStatus,
    branchTypes,
    setImageOpen,
    onClick: async (key, record) => {
      switch (key) {
        case "edit":
          try {
            setAction(["SECTOR_FORM", record]);
            setActiveTab("default");
          } catch (error: any) {
            message.error(error.message ?? "Хүсэлт амжилтгүй боллоо.");
          }
      }
    },
    onDelete: async (id) => {},
  });

  const onCloseAll = () => {
    setAction([]);
  };

  return (
    <PageLayout
      title="Манай салбарууд"
      subTitle="Манай салбарууд"
      breadcrumb={breadcrumbs}
      extra={[
        <Flex key={1} gap="md" align="center">
          <Button
            onClick={() => {
              setActiveTab("default");
              setAction(["SECTOR_FORM"]);
            }}
            size="sm">
            Салбар нэмэх
          </Button>
        </Flex>,
      ]}>
      <Flex direction={"column"} gap="md" sx={{ minHeight: "60vh", height: "100%" }}>
        <Collapse in={preAlert}>
          <Alert
            styles={{ root: { backgroundColor: "#FCDDEC" }, message: { color: "#44566c", fontWeight: 500 } }}
            onClose={() => setPreAlert(false)}
            radius="md"
            color="red"
            withCloseButton
            title="Анхаар!">
            Танай бизнес олон салбартай бол нэмж бүртгэнэ үү.
          </Alert>
        </Collapse>
        <Box
          sx={(theme) => ({
            background: theme.white,
            borderRadius: 8,
            border: `1px solid ${theme.colors.gray[2]}`,
            padding: "16px 20px",
            height: "100%",
            minHeight: "60vh",
          })}
          w={"100%"}>
          <Table ref={tableRef} name="sector.table" filters={{ query: "" }} columns={columns} loadData={(data) => BranchApi.list(data!)} />
        </Box>
      </Flex>

      <Drawer
        styles={{ body: { overflowY: "scroll" } }}
        position="right"
        opened={action[0] === "SECTOR_FORM"}
        onClose={() => setAction([])}
        withCloseButton={false}
        padding="xl"
        size="70%">
        <PageLayout extra={[<CloseButton onClick={() => onCloseAll()} key={1} />]} title="Салбарын мэдээлэл">
          <Tabs variant="pills" value={activeTab}>
            <Tabs.List>
              <Tabs.Tab leftSection={<IconBuilding color={activeTab === "default" ? "white" : "gray"} />} value="default">
                <Text c={activeTab === "default" ? "" : "gray"}>Үндсэн мэдээлэл</Text>
              </Tabs.Tab>
              <Tabs.Tab disabled={!action[1] && !id} leftSection={<IconSpeakerphone color={activeTab === "address" ? "white" : "gray"} />} value="address">
                <Text c={activeTab === "address" ? "" : "gray"}>Хаягийн мэдээлэл</Text>
              </Tabs.Tab>
            </Tabs.List>
            <Divider mt="md" mb="md" />
            <Tabs.Panel value="default">
              <SectorForm setActiveTab={setActiveTab} setId={setId} action={action} reload={async () => await reloadTable()} onClose={() => onCloseAll()} />
            </Tabs.Panel>
            <Tabs.Panel value="address">
              <SectorAddressForm justCreatedId={id} reload={async () => await reloadTable()} id={action[1]?.id} action={action} onClose={() => onCloseAll()} />
            </Tabs.Panel>
          </Tabs>
        </PageLayout>
      </Drawer>
      <Modal title={false} opened={imageOpen[0] === "open"} onClose={() => setImageOpen([])} padding={0} withCloseButton={false} centered>
        <Image w={"100px"} src={imageOpen[1]} />
      </Modal>
    </PageLayout>
  );
}

const useHeader = ({
  onClick,
  isDefault,
  branchStatus,
  branchTypes,
  setImageOpen,
  onDelete,
}: {
  onClick: (key: string, record: any) => void;
  branchStatus: any;
  isDefault: (key: boolean) => JSX.Element;
  branchTypes: IReference[];
  setImageOpen: (e: any) => void;
  onDelete: (id: string) => void;
}): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => `${index + 1}`,
    width: "30px",
  },
  {
    title: "Лого",
    render: (record) => (
      <Avatar
        style={{ cursor: record?.logo ? "pointer" : "not-allowed" }}
        disabled={!record?.logo}
        onClick={() => setImageOpen(["open", record?.logo])}
        src={record?.logo}
        size={50}
        radius={4}
        component="button">
        <IconPhoto />
      </Avatar>
    ),
  },

  {
    title: "Салбар",
    dataIndex: "refCode",
    render: (record) => (
      <Text w={"max-content"} c="teal">
        # {record?.refCode || "-"}
      </Text>
    ),
  },
  {
    title: "Салбарын нэр",
    dataIndex: "name",
    render: (record) => <Text w="max-content">{record?.name || "-"}</Text>,
  },
  {
    title: "Төрөл",
    dataIndex: "type",
    render: (record) => (
      <Text c="gray" fw={600} style={{ whiteSpace: "nowrap" }}>
        {branchTypes?.find((item: any) => item.code === record.type)?.name || "-"}
      </Text>
    ),
  },
  {
    title: "Ажилтан",
    dataIndex: "names",
    render: (record) => (
      <Text style={{ whiteSpace: "nowrap" }}>
        {record.regUser?.lastName ? record.regUser?.lastName[0] + ". " : ""} {record?.regUser?.firstName ?? "-"}
      </Text>
    ),
  },
  {
    title: "Утас",
    dataIndex: "phone",
    render: (record) => `${record.regUser?.phone ?? "-"}`,
  },
  {
    title: "Үндсэн эсэх",
    dataIndex: "isDefault",
    render: (record) => {
      return isDefault(record.isDefault);
    },
  },
  {
    title: "Buyer эсэх",
    dataIndex: "isBuyer",
    render: (record) => {
      return isDefault(record.isBuyer);
    },
  },
  {
    title: "Supplier эсэх",
    dataIndex: "isSupplier",
    render: (record) => {
      return isDefault(record.isSupplier);
    },
  },
  {
    title: "Төлөв",
    dataIndex: "status",
    render: (record) => (
      <Badge color={branchStatus?.find((f: IReference) => f.code === record.branchStatus)?.color ?? "blue"} variant="outline">
        {branchStatus?.find((f: IReference) => f.code === record.branchStatus)?.name ?? "-"}
      </Badge>
    ),
  },
  {
    title: "Бүртгэсэн",
    dataIndex: "date",
    render: (record) => <Text w="max-content">{moment(record?.createdAt).format("YYYY-MM-DD hh:mm") ?? "-"}</Text>,
  },
  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <Tooltip label="Засах">
            <ActionIcon variant="light" onClick={() => onClick("edit", record)} color="teal">
              <IconEdit />
            </ActionIcon>
          </Tooltip>
          {/* <Tooltip label="Устгах">
            <ActionIcon variant="light" onClick={() => onDelete(record.id)} color="red">
              <IconTrash />
            </ActionIcon>
          </Tooltip> */}
        </div>
      );
    },
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Эхлэл",
  },
  {
    label: "Манай салбарууд",
  },
];

import { Badge, MantineColor } from "@mantine/core";

interface ITagProps {
  color: MantineColor;
  name: string;
}

function Tag({ color, name }: ITagProps) {
  if (color.slice(0, 1) === "#")
    return (
      <Badge style={{ border: `${color} 1px solid`, color: color }} size="md" radius="xl" variant="outline">
        {name}
      </Badge>
    );
  else
    return (
      <Badge color={color} size="md" radius="xl" variant="outline">
        {name}
      </Badge>
    );
}

export { Tag };
